import React from "react"
import { Link } from "gatsby"
import WorkGrid from "../components/WorkGrid"
import SEO from "../components/shared/seo"
import { useProjectData } from "../hooks/useProjectData"

let data
const WorkPage = () => {
  data = useProjectData()

  return (
    <div>
      <SEO title="Work" />
      <h1>My Work</h1>
      <h4>Assorted code and pixels</h4>
      <div className="work-description" />
      <WorkGrid projectData={data} />
      <Link to="/">Go back to the homepage</Link>
    </div>
  )
}

export default WorkPage
