/* eslint-disable */
import React, { Component } from "react"
import ProjectCard from "./projectCard"
import SEO from "../components/shared/seo"
import ReactModal from "react-modal"
import Image from "gatsby-image"

let Masonry
if (typeof window !== "undefined") {
  Masonry = require("masonry-layout")
}

ReactModal.setAppElement("#page-wrap")

export default class WorkGrid extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalOpen: false,
      data: [],
      modalData: {
        description: "",
        title: "",
        url: "",
        image: {},
      },
    }
  }

  componentDidMount(props) {
    this.setState({
      data: this.props.projectData,
    })

    if (window) {
      setTimeout(
        function() {
          var grid = document.querySelector(".grid")
          if (!grid) return
          var msnry = new Masonry(grid, {
            // options...
            itemSelector: ".grid-item",
            stagger: 10,
            gutter: 0,
            columnWidth: 1,
            isOriginLeft: true,
            isResizable: false,
            // columnWidth: 200
          })

          msnry.isLayoutInited = true
          msnry.layout()
          window.mason = msnry
          grid.style.visibility = "visible"
        }.bind(this),
        150
      )
    }
  }

  handleModalOpen = event => {
    this.setState({
      ...this.state,
      isModalOpen: true,
    })
  }

  handleModalClose = event => {
    this.setState({
      isModalOpen: false,
    })
  }

  handleModalClick = proj => {
    this.setState({
      modalData: proj,
      isModalOpen: true,
    })
  }

  render() {
    let projects
    let that = this

    projects = this.state.data.map(function({ node: p }, i) {
      return (
        <div onClick={e => that.handleModalClick(p)} key={i * 100}>
          <ProjectCard
            title={p.title}
            url={p.url}
            imageData={p.image.childImageSharp.fluid}
            description={p.description}
            thumbSize={p.size}
          />{" "}
        </div>
      )
    })

    const { description, title, url, image } = this.state.modalData
    let imageL, urlL

    // if no image dont break render
    if (Object.keys(image).length) {
      imageL = <Image fluid={image.childImageSharp.fluid} alt={title} />
    } else {
      imageL = <div> </div>
    }

    // if project preview not available inform user
    if (!url.length) {
      urlL = <i className="accent" />
    } else {
      urlL = (
        <a target="_blank" className="modal-ext-link accent" href={url}>
          {/* {" "}
          {url}{" "} */}
          <button class="button is-info is-light">Preview</button>
        </a>
      )
    }

    return (
      <React.Fragment>
        <div>
          <SEO title="Work" />
          <div className="work-description" />{" "}
          <div
            className="grid"
            style={{
              visibility: "hidden",
            }}
          >
            {" "}
            {projects}{" "}
          </div>{" "}
        </div>{" "}
        <ReactModal
          isOpen={this.state.isModalOpen}
          onRequestClose={this.handleModalClose}
        >
          <div className="modal-inner">
            <div className="c-modal-content">
              <div className="modal-left">
                <h2> {title} </h2> <h6> {description} </h6> {urlL}
              </div>
              <div className="modal-right"> {imageL} </div>{" "}
            </div>
          </div>{" "}
          <button
            className="modal-close-button accent"
            onClick={this.handleModalClose}
          >
            {" "}
            ⓧ{" "}
          </button>{" "}
        </ReactModal>{" "}
      </React.Fragment>
    )
  }
}
