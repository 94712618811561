import { useStaticQuery, graphql } from 'gatsby';

export const useProjectData = () => {
  const data = useStaticQuery(graphql`
      {
        allProjectsJson {
          edges {
            node {
              title
              image {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              description
              size
              url
            }
          }
        }
      }
     `);

  return data.allProjectsJson.edges;
}