import React from "react"
import Image from "gatsby-image"

const ProjectCard = ({ title, imageData, thumbSize }) => {
  const itemClasses = `grid-item grid-item-${thumbSize}`

  return (
    <div className={itemClasses}>
      <div
        className="overlay"
        style={{
          display: "block",
        }}
      >
        <h4 className="project-title"> {title} </h4>{" "}
      </div>{" "}
      <div className="project-item">
        <Image fluid={imageData} alt={title} />{" "}
      </div>{" "}
    </div>
  )
}

export default ProjectCard
